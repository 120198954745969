var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-3"},[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between mb-4"},[_c('div',{staticClass:"top-header mb-0 font-weight-bold"},[_vm._v(" "+_vm._s("Backups")+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":_vm.getBackups}},on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v("Reload")])])],1),[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"server-items-length":_vm.total,"options":_vm.options,"items":_vm.backups,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.createdAt))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end px-4"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.toRestore(item)}}},on),[_c('v-icon',[_vm._v("mdi-backup-restore")])],1)]}}],null,true)},[_c('span',[_vm._v("Restore Backup")])]),(_vm.isSuper || _vm.isAdmin)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":"error"},on:{"click":function($event){return _vm.toDelete(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Backup")])])]:_vm._e()],2)]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"300px","transition":"dialog-transition"},model:{value:(_vm.deleteBackup.dialog),callback:function ($$v) {_vm.$set(_vm.deleteBackup, "dialog", $$v)},expression:"deleteBackup.dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"error"}},[_vm._v(" Delete Backup ")]),_c('v-card-text',{staticClass:"mt-3"},[_c('div',{staticClass:"pa-2 text-center"},[_vm._v("Are you sure ?")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancelDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","loading":_vm.deleteBackup.loading,"color":"error"},on:{"click":_vm.confirmDelte}},[_vm._v("Delete")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"300px","transition":"dialog-transition"},model:{value:(_vm.restoreBackup.dialog),callback:function ($$v) {_vm.$set(_vm.restoreBackup, "dialog", $$v)},expression:"restoreBackup.dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_vm._v(" Restore Backup ")]),_c('v-card-text',{staticClass:"mt-3"},[_c('div',{staticClass:"pa-2 text-center"},[_vm._v("Are you sure ?")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.cancelDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","loading":_vm.restoreBackup.loading,"color":"primary"},on:{"click":_vm.confirmRestore}},[_vm._v("Restore")])],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }